window.full_modal_close = function () {
  modal_close();

  $("body").removeClass("body-no-scroll-pager-modal");
  $('#full-modal').html('');

  $('#overlay').fadeOut();
  let modal = document.querySelector('#full-modal');
  modal.style.visiblity = 'hidden';
  modal.classList.remove('open');
}

window.full_modal_open = function (content) {

  $("body").addClass("body-no-scroll-pager-modal");
  $('#full-modal').html(content);
  $('#full-modal').addClass('open');
}

window.center_modal_open = function (content) {

  $("body").addClass("body-no-scroll-pager-modal");
  //$("#overlay").show();
  $('#center-modal').find('.content').html(content);
  $('#center-modal').addClass('open');
}

window.center_modal_close = function (content) {
  $("body").removeClass("body-no-scroll-pager-modal");
  $('#center-modal').find('.content').html('');
  $('#center-modal').removeClass('open');
}



